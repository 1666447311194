import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    Grid,
    Card,
    Container,
    Typography,
    Stack,
    Tab,
    List,
    Divider,
    ListItem,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Helmet } from 'react-helmet-async';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import About from './event-details/About'
import Timeline from './event-details/Timeline'
import Faq from './event-details/Faq'
import TabsValue from './event-details/Tabs'
import Hero from './event-details/Hero'
import Eligibility from './event-details/Eligibility'

// New Styled Component for Program Information
const StyledProgramInfo = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
}));

const ComponentFactory = forwardRef(({type, componentData,allRefs,visibleComponents}, ref) => {


  
    // const tabs = Object.values(componentData)
    const tabs = visibleComponents?.filter(item => item.type !== 'hero' && item.type !== 'tabs').map(item => ({
        value: item.type,
        label: item.type.charAt(0).toUpperCase() + item.type.slice(1)
      }));

 switch(type) {
    case 'about':
        return <About about={componentData} ref={ref} />
    case 'timeline':
        return <Timeline timeline={componentData} ref={ref}     />
    case 'faq':
        return <Faq faq={componentData} ref={ref} />
    case 'tabs':
        return <TabsValue refs={allRefs} tabs={tabs}/>;
    case 'hero':
         return <Hero config={componentData} />
     case 'program info':
         return <StyledProgramInfo>
             <Typography variant="h5" component="h2" gutterBottom>
                 Program Highlights
             </Typography>
             <List>
                 <ListItem>
                     <CheckCircleIcon color="primary" sx={{ marginRight: 2 }} />
                     <ListItemText primary="Live evaluation of technical skills & assessment." />
                 </ListItem>
                 <ListItem>
                     <CheckCircleIcon color="primary" sx={{ marginRight: 2 }} />
                     <ListItemText primary="Hard copy of certificate will be issued to all participants." />
                 </ListItem>
                 <ListItem>
                     <CheckCircleIcon color="primary" sx={{ marginRight: 2 }} />
                     <ListItemText primary="Lunch will be provided to all participants." />
                 </ListItem>
                 <ListItem>
                     <CheckCircleIcon color="primary" sx={{ marginRight: 2 }} />
                     <ListItemText primary="Beginner level coaching manual (hard copy)." />
                 </ListItem>
                 <ListItem>
                     <CheckCircleIcon color="primary" sx={{ marginRight: 2 }} />
                     <ListItemText primary="Giveaways to all participants." />
                 </ListItem>
             </List>
         </StyledProgramInfo>
    case 'eligibility':
        return <Eligibility eligibilityData={componentData} ref={ref} />
    default:
        return null;
 }      
})

export default ComponentFactory
