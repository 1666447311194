import React, { forwardRef } from 'react'
import {
  Box,
  Button,
  Grid,
  Card,
  Typography,
  Stack,
  Avatar,
} from '@mui/material';
import Iconify from '../../../components/Iconify';

import DynamicAspectRatioImage from '../../../components/DynamicAspectRatioImage';

// Helper function to determine the appropriate icon based on the heading
const getIcon = (heading) => {
  switch (heading) {
    case 'Time':
      return 'solar:clock-circle-bold';
    case 'Location':
      return 'logos:google-maps';
    default:
      return 'solar:calendar-bold';
  }
};

const About = forwardRef(({ about }, ref) => (
  <Card
    ref={ref}
    sx={{
      m: { xs: 0, md: 2 },
      px: { xs: 2, md: 8 },
      py: 4,
      bgcolor: 'white',
      mb: { xs: 3 },
    }}
  >
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <Card sx={{ p: 2, bgcolor: 'white', boxShadow: 'none' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              {about?.data?.logo && (
                <DynamicAspectRatioImage size={56} uri={about?.data?.logo} />
              )}
              {!about?.data?.logo && (
                <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    bgcolor: 'primary.main',
                    fontSize: '1.5rem',
                  }}
                >
                  {about?.data?.name?.charAt(0)}
                </Avatar>
              )}
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Organized by
                </Typography>
                <Typography variant="h6" color="text.primary" fontWeight={600}>
                  {about?.data?.name}
                </Typography>
              </Box>
            </Stack>
          </Card>
          <Box>
            {about?.data?.eventDetails?.map((item) => (
              <Box key={item.id} mb={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '24px',
                    }}
                  >
                    <Iconify
                      fontSize={24}
                      icon={item.icon || getIcon(item.heading)}
                      sx={{ color: 'primary.main' }}
                    />
                  </Box>
                  <Box>
                    <Typography variant="body1" color="text.secondary">
                      {item.label}
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary">
                      {item.value}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ))}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  </Card>
));

export default About