// Function: Eligibility

import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';

// Styled Component for Eligibility Information
const StyledEligibility = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(4),
}));

const Eligibility = React.forwardRef(({ eligibilityData }, ref) => {
    return (
        <StyledEligibility ref={ref}>
            <Typography variant="h5" component="h2" gutterBottom>
                Eligibility Criteria
            </Typography>
            <Typography variant="body1" gutterBottom>
                Participants who have completed the PSM Beginner Online CCP are eligible to join the program.
            </Typography>
        
        </StyledEligibility>
    );
});

export default Eligibility; 