import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Helmet } from 'react-helmet-async';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import {
  Box,
  Button,
  Grid,
  Card,
  Container,
  Typography,
  Stack,
  Tab,
  List,
  Divider,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import Image from '../../components/Image';
import useResponsive from '../../hooks/useResponsive';
import { fetchOrgData } from '../../redux/slices/org';
import { fetchEventDetails, fetchSimilarEventList } from '../../redux/slices/event';
import MainLayout from '../../layouts/main';
import ClubHeroCta from '../../krida/cta/ClubHeroCta';
import SportLabel from '../../krida/label/SportLabel';
import DynamicAspectRatioImage from '../../components/DynamicAspectRatioImage';
import CarouselV2 from '../../krida/carousel/carousel-v2';
import KridaLabel from '../../krida/label/KridaLabel';
import Iconify from '../../components/Iconify';
import BOOK_FREE_TRIAL_BACKGROUND from '../../assets/Event.svg';
import ComponentBlock from '../../krida/ComponentBlock';
import { API_STATUS, KRIDA_API } from '../../config';
import LoadingScreen from '../../components/LoadingScreen';
import EventCard from '../../krida/event/EventCard';
import Faq from './event-details/Faq';
import Hero from './event-details/Hero';
import Timeline from './event-details/Timeline';
import About from './event-details/About';
import TabsValue from './event-details/Tabs';
import config from './config.json';
import ComponentFactory from './ComponentFactory';
import axios from '../../utils/axios'

const StyledLandingContainer = styled(Box)({
  padding: '64px !important', // 8 * 8px
  background: 'linear-gradient(115deg, #000101 0%, #00204F 100%)',
  overflow: 'hidden',
  [`@media (max-width: 600px)`]: {
    marginTop: 0,
    background: 'linear-gradient(115deg, #000101 0%, #00204F 100%)',
    padding: '0 !important',
  },
});

const StyledEventDetailsContainer = styled(Box)({
  padding: '80px', // 10 * 8px
  paddingTop: '0px',
  paddingBottom: '32px', // 4 * 8px
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  '@media (max-width: 600px)': {
    marginTop: '0', // 2 * 8px
    padding: '0 16px', // 2 * 8px
  },
});

// New Styled Component for Program Information
const StyledProgramInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const Main = () => {
  const dispatch = useDispatch();
  const { orgDataLoader, orgData } = useSelector((state) => state.org);
  const { user, currentPlayer } = useSelector((state) => state.user);

  const { eventListLoader, similarEventData, eventDetailsLoader, eventListData, eventDetailsData } = useSelector(
    (state) => state.event
  );
  const navigate = useNavigate();
  const [organisationData, setOrganisationData] = useState([]);
  const [centers, setCenters] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const smDown = useResponsive('down', 'sm');
  const mdUp = useResponsive('up', 'md');

  const { orgId, eventId, clubId } = useParams();
  const [value, setValue] = useState(0);
  const [accordianData, setAccordianData] = useState([]);
  const [controlled, setControlled] = useState(false);
  const [pageTitle, setPageTitle] = useState('KRIDA | Play, Train & Compete');
  const [tabValue, setTabValue] = useState('timeline');
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const aboutRef = useRef(null);
  const timelineRef = useRef(null);
  const faqRef = useRef(null);

  const componentRefs = {
    about: aboutRef,
    timeline: timelineRef,
    faq: faqRef
  };

  const handleChangeControlled = (panel) => (event, isExpanded) => {
    setControlled(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchEventDetails({ eventId, clubId }));
      try {
        const data = await axios.get(`http://localhost:5001/api/v1/kl/event/dummy_event_id`);
        console.log("NEW URL EVENT DETAILS", data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [dispatch, eventId, clubId]);

  // useEffect(() => {
  //   const acc = [];
  //   if (eventDetailsData?.id) {
  //     if (eventDetailsData?.metadata?.features) {
  //       acc.push({
  //         id: 'feature',
  //         value: 'feature',
  //         heading: 'Key Features',
  //         list: eventDetailsData?.metadata?.features,
  //       });
  //       setControlled('feature');
  //     }
  //     if (eventDetailsData?.metadata?.tnc) {
  //       acc.push({
  //         id: 'tnc',
  //         value: 'tnc',
  //         heading: 'Terms & Conditions',
  //         list: eventDetailsData?.metadata?.tnc,
  //       });
  //     }
  //     setAccordianData(acc);
  //     if (eventDetailsData?.organization?.id) {
  //       dispatch(fetchSimilarEventList({ orgId: eventDetailsData?.organization?.id }));
  //       setPageTitle(`${eventDetailsData.organization.name} | Summer Camp 2024`);
  //     }
  //   }
  // }, [eventDetailsData]);
  // if (eventDetailsLoader === API_STATUS.LOADING) {
  //   return <LoadingScreen />;
  // }
  console.log("CURRENT USER", user.id)
  const visibleComponents = config.componentList.filter(component => component.isVisible);
  console.log("refs in main", componentRefs)  

  return (
    <MainLayout>
      <StyledEventDetailsContainer>
        {/* Existing Components */}
        {visibleComponents.map((component) => (
          <ComponentFactory key={component.id} type={component.type} componentData={component} 
            ref={componentRefs[component.type]}
            allRefs={componentRefs}
            visibleComponents={visibleComponents}
          />
        ))}

      </StyledEventDetailsContainer>
    </MainLayout>
  );
};

export default Main;
